import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Container, LinksContainer } from "./styles";

const Footer = ({ t, lang }: { t: Record<string, string>; lang: string }) => {
  return (
    <>
      <Container>
        <LinksContainer>
          <Link
            to={lang === "pt" ? "/" : "/en"}
            tw="inline-flex items-center justify-center py-2"
          >
            <StaticImage
              src="../../../images/logo.png"
              alt={t.logoAlt}
              placeholder="blurred"
              layout="fixed"
              height={40}
              tw="h-10 w-auto"
            />
          </Link>
          <p tw="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
            © {new Date().getFullYear()} {t.site}
          </p>
          <span tw="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            <a
              href={t.facebook}
              target="_blank"
              rel="noopener noreferrer nofollow"
              tw="text-gray-500 flex gap-2 items-center"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                tw="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
              <span>Facebook</span>
            </a>

            <a
              href={t.instagram}
              target="_blank"
              rel="noopener noreferrer nofollow"
              tw="ml-3 text-gray-500 flex gap-2 items-center"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                tw="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
              <span>Instagram</span>
            </a>
          </span>
        </LinksContainer>
      </Container>
      <div tw="fixed bottom-4 right-4 md:bottom-6 md:right-6 inline-flex z-50">
        <a
          tw="bg-[#25d366] p-3 rounded-full w-16 h-16"
          target="_blank"
          rel="noopener noreferrer nofollow"
          href="https://wa.me/351938609185?text=Quero%20marcar%20uma%20aula%20de%20surf"
        >
          <StaticImage
            src="../../../images/whatsapp.svg"
            alt="WhatsApp"
            placeholder="blurred"
            layout="fixed"
            height={40}
            tw="h-10 w-10 text-white z-50"
          />
        </a>
      </div>
    </>
  );
};

export default Footer;
