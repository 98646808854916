import { css } from "@emotion/react";

export const customStyles = css`
  html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  body {
    background-color: #fff;
    font-family: "Dosis", Arial, Helvetica, sans-serif;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Kalam", Arial, Helvetica, sans-serif;
  }

  section {
    scroll-margin-top: 96px;
  }

  .text-shadow {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.52);
  }
`;
