import tw from "twin.macro";

const styles = {
  header: ({ sticky }: { sticky: boolean }) => [
    tw`z-50 bg-transparent fixed top-0 w-full`, // base styles
    sticky && tw`bg-gray-100 bg-opacity-90`, // conditional styles
  ],
  column: tw`w-1/2`,
};

export default styles;
